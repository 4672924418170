import React, { lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import ProtectedRoute from '../shared/components/auth/ProtectedRoute'
import { ROUTER_PATH } from '../constants'
import AuthorizedLayout from '../shared/components/layout/AuthorizedLayout'
import UnauthorizedLayout from '../shared/components/layout/UnauthorizedLayout'
import AdsVisualisation from './AdsVisualisation/AdsVisualisation'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Code splitting and lazy loading
const Dashboard = lazy(() =>
	import(/* webpackChunkName: "Dashboard" */ './Dashboard'),
)

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_API_KEY || process.env.STRIPE_API_KEY || '',
	{
		locale: window.localStorage.getItem('i18nextLng') || 'auto',
	},
)

const Report = lazy(() => import(/* webpackChunkName: "Report" */ './Report'))
const Login = lazy(() =>
	import(/* webpackChunkName: "Login" */ './Login/Login'),
)
const SignUp = lazy(() =>
	import(/* webpackChunkName: "SignUp" */ './Register/SignUp'),
)
const Register = lazy(() =>
	import(/* webpackChunkName: "Register" */ './Register/Register'),
)
const Tasks = lazy(() =>
	import(/* webpackChunkName: "Tasks" */ './Tasks/Tasks'),
)
const KeywordPlanner = lazy(() =>
	import(
		/* webpackChunkName: "KeywordPlanner" */ './KeywordPlanner/KeywordPlanner'
	),
)
const KeywordRanking = lazy(() =>
	import(
		/* webpackChunkName: "KeywordRanking" */ './KeywordRanking/KeywordRanking'
	),
)
const TechnicalAudit = lazy(() =>
	import(/* webpackChunkName: "TechnicalAudit" */ './TechnicalAudit/Audit'),
)
const PriceScalper = lazy(() =>
	import(
		/* webpackChunkName: "PriceScalper" */ './PriceScalping/PriceScalping'
	),
)
const CategoryCreator = lazy(() =>
	import(
		/* webpackChunkName: "CategoryCreator" */ './CategoryCreator/CategoryCreator'
	),
)
const ResetPassword = lazy(() =>
	import(
		/* webpackChunkName: "ResetPassword" */ './ResetPassword/ResetPassword'
	),
)
const ForgetPassword = lazy(() =>
	import(
		/* webpackChunkName: "ForgetPassword" */ './ForgotPassword/ForgetPassword'
	),
)
const ProjectSettings = lazy(() =>
	import(/* webpackChunkName: "ProjectSettings" */ './Project/ProjectSettings'),
)
const Profile = lazy(() =>
	import(/* webpackChunkName: "Profile" */ './Profile/Profile'),
)
const Company = lazy(() =>
	import(/* webpackChunkName: "Company" */ './Company/Company'),
)
const PostScheduler = lazy(() =>
	import(
		/* webpackChunkName: "PostScheduler" */ './PostScheduler/PostScheduler'
	),
)
const EditPost = lazy(() =>
	import(/* webpackChunkName: "EditPost" */ './PostScheduler/EditPost'),
)
const Subscription = lazy(() =>
	import(/* webpackChunkName: "Subscription" */ './Subscription/Subscription'),
)
const ReportTemplate = lazy(() =>
	import(
		/* webpackChunkName: "ReportTemplate" */ './Report/ReportTemplateCreation'
	),
)
const ProjectWizard = lazy(() =>
	import(
		/* webpackChunkName: "ProjectWizard" */ './ProjectWizard/ProjectCreationWizard'
	),
)
const ROASAllogator = lazy(() =>
	import(
		/* webpackChunkName: "ROASAllogator" */ './ROASAllogator/ROASAllogator'
	),
)
const ContentCreator = lazy(() =>
	import(
		/* webpackChunkName: "ContentCreator" */ './ContentCreator/ContentCreator'
	),
)
const AiFinetuneFileCheck = lazy(() =>
	import(
		/* webpackChunkName: "AiFinetuneFileCheck" */ './AiFinetuneFileCheck/AiFinetuneFileCheck'
	),
)
const WordPressTools = lazy(() =>
	import(
		/* webpackChunkName: "WordPressTools" */ './WordPressTools/WordPressTools'
	),
)
const AiContentInBulk = lazy(() =>
	import(/* webpackChunkName: "Ai" */ './AiContentInBulk/AiContentInBulk'),
)
const CustomizeReport = lazy(() =>
	import(
		/* webpackChunkName: "CustomizeReport" */ './CustomizeReport/CustomizeReport'
	),
)
const RankPredictor = lazy(() =>
	import(
		/* webpackChunkName: "Rank predictor" */ './RankPredictor/RankPredictor'
	),
)
const GoogleAdsChangeEvents = lazy(() =>
	import(
		/* webpackChunkName: "GoogleAdsChangeEvents" */ './GoogleAdsChangeEvents/GoogleAdsChangeEvents'
	),
)

const ProjectInvitation = lazy(() =>
	import(
		/* webpackChunkName: "ProjectInvitation" */ './ProjectInvitation/ProjectInvitation'
	),
)

const Router = ({ isAuthenticated }) => {
	const authenticatedRoutes = (
		<StripeElements stripe={stripePromise}>
			<AuthorizedLayout>
				<Switch>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.DASHBOARD}
						component={Dashboard}
						title='Dashboard'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.PROJECT_SETTINGS}
						component={ProjectSettings}
						title='Setting'
					/>

					<ProtectedRoute
						path={ROUTER_PATH.SUBSCRIPTION}
						component={Subscription}
						title='Subscription'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.PROFILE}
						component={Profile}
						title='Profile'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.COMPANY}
						component={Company}
						title='Company'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.REPORTS}
						component={Report}
						title='Report'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.CUSTOM_REPORT}
						component={Report}
						title='Report'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.REPORTS_TEMPLATE}
						component={ReportTemplate}
						title='Report Template'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.KEYWORD_PLANNER}
						component={KeywordPlanner}
						title='Keyword planner'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.KEYWORD_RANKING}
						component={KeywordRanking}
						title='Keyword ranking'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.AUDIT}
						component={TechnicalAudit}
						title='Technical audit'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.PRICE_SCALPING}
						component={PriceScalper}
						title='Price scalper'
					/>
					<ProtectedRoute
						path={ROUTER_PATH.CATEGORY_CREATOR}
						component={CategoryCreator}
						title='Category creator'
					/>

					<ProtectedRoute
						exact
						path={ROUTER_PATH.ADS_VISUALISATION}
						component={AdsVisualisation}
						title='Ads visualisation'
					/>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.PROJECT_CREATION_WIZARD_ROUTE}
						component={ProjectWizard}
						title='Project creation wizard'
					/>
					<Route
						path={ROUTER_PATH.TASKS}
						component={Tasks}
						title='tasks'
						exact
					/>
					<Route
						path={ROUTER_PATH.POST_SCHEDULER}
						component={PostScheduler}
						title='post scheduler'
						exact
					/>
					<Route
						path={ROUTER_PATH.POST_SCHEDULER_CALENDAR}
						component={PostScheduler}
						title='calendar'
						exact
					/>
					<Route
						path={ROUTER_PATH.POST_SCHEDULER_WITH_DATE}
						component={PostScheduler}
						title='post scheduler with date'
						exact
					/>
					<Route
						path={ROUTER_PATH.AI_CONTENT_CREATOR}
						component={ContentCreator}
						title='content creator'
						exact
					/>
					<ProtectedRoute
						path={ROUTER_PATH.AI_CONTENT_IN_BULK}
						component={AiContentInBulk}
						title='Content bulk creation'
					/>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.ROAS_ALLOGATOR}
						component={ROASAllogator}
						title='ROAS allogator'
					/>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.EDIT_POST_ROUTE}
						component={PostScheduler}
						title='Edit post'
					/>
					<Route
						path={ROUTER_PATH.AI_FINETUNE_FILE_CHECK}
						component={AiFinetuneFileCheck}
						title='ai finetune file check'
						exact
					/>
					{/* <ProtectedRoute
						exact
						path={ROUTER_PATH.WP_CONTENT}
						component={WordPressTools}
						title='WP tools'
					/>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.WP_CONTENT_EDIT_BY_TYPE_AND_ID}
						component={WordPressTools}
						title='Edit WP'
					/>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.WP_CONTENT_CREATE_BY_TYPE}
						component={WordPressTools}
						title='Create WP'
					/>
					<ProtectedRoute
						exact
						path={ROUTER_PATH.WP_CONTENT_POST_BY_ID}
						component={WordPressTools}
						title='Show WP'
					/> */}
					<Route
						path={ROUTER_PATH.CUSTOMIZE_REPORT}
						component={CustomizeReport}
						title='Customize report'
						exact
					/>
					{/* <ProtectedRoute
						exact
						path={ROUTER_PATH.RANK_PREDICTION}
						component={RankPredictor}
						title='Rank predictor'
					/> */}
					<ProtectedRoute
						path={ROUTER_PATH.GOOGLE_CHANGE_EVENTS}
						component={GoogleAdsChangeEvents}
						title='Google Ads change events'
					/>
					<Redirect to={ROUTER_PATH.HOME} />
				</Switch>
			</AuthorizedLayout>
		</StripeElements>
	)
	const unauthenticatedRoutes = (
		<UnauthorizedLayout>
			<Switch>
				<Route exact path={ROUTER_PATH.LOGIN} component={Login} title='Login' />
				<Route
					exact
					path={ROUTER_PATH.SIGNUP}
					component={SignUp}
					title='SignUp'
				/>
				<Route
					exact
					path={ROUTER_PATH.REGISTER}
					component={Register}
					title='Register'
				/>
				<Route exact path={ROUTER_PATH.LOGIN} component={Login} title='Login' />
				<Route
					exact
					path={ROUTER_PATH.RESET_PASSWORD}
					component={ResetPassword}
					title='ResetPassword'
				/>
				<Route
					exact
					path={ROUTER_PATH.FORGET_PASSWORD}
					component={ForgetPassword}
					title='ForgetPassword'
				/>
				<Route
					exact
					path={ROUTER_PATH.PROJECT_INVITATION}
					component={ProjectInvitation}
					title='ProjectInvitation'
				/>
				<Route
					path={ROUTER_PATH.AUTOMATIC_REPORT}
					component={Report}
					title='Report'
				/>
				<Redirect to={ROUTER_PATH.LOGIN} />
			</Switch>
		</UnauthorizedLayout>
	)

	const content = isAuthenticated ? authenticatedRoutes : unauthenticatedRoutes
	return content
}

export default Router
